import React from 'react';
import cls from 'classnames';
import Icon from '../icon/Icon';

import './Label.css';

const Label = ({ icon, className, children }) => {
  return (
    <div className={cls('label', className)}>
      {icon ? <Icon name={icon} className="label__icon" aria-hidden="true" /> : null}
      {children}
    </div>
  );
};

export default Label;
