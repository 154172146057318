import React from 'react';
import Label from '../label/Label';

const Date = ({ date }) => {
  return (
    <Label icon="calendar">
      <time>{date}</time>
    </Label>
  );
};

export default Date;
