import React from 'react';
import cls from 'classnames';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Content from '../../components/content';
import Meta from '../../components/meta';
import Date from '../../components/date';

import './BlogPost.css';

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
      }
      html
    }
  }
`;

const BlogPostTemplate = ({ data }) => {
  const { markdownRemark: post } = data;
  const { title, description, image, date, published } = post.frontmatter;

  return (
    <Layout>
      <Meta title={title} description={description} image={image}>
        <body className={cls('post', { 'post--draft': !published })} />
      </Meta>

      <Content>
        <h1>{title}</h1>

        <Date date={date} />

        {!published && (
          <blockquote>
            <p>This is a draft.</p>
          </blockquote>
        )}

        <p className="post__description">{description}</p>

        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Content>
    </Layout>
  );
};

export default BlogPostTemplate;
